// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-page-tsx": () => import("../src/templates/index-page.tsx" /* webpackChunkName: "component---src-templates-index-page-tsx" */),
  "component---src-templates-about-page-tsx": () => import("../src/templates/about-page.tsx" /* webpackChunkName: "component---src-templates-about-page-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-contact-page-tsx": () => import("../src/templates/contact-page.tsx" /* webpackChunkName: "component---src-templates-contact-page-tsx" */),
  "component---src-templates-portfolio-page-tsx": () => import("../src/templates/portfolio-page.tsx" /* webpackChunkName: "component---src-templates-portfolio-page-tsx" */),
  "component---src-pages-404-tsx": () => import("../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */)
}

